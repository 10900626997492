<template>
  <b-card>
    <b-tabs>
      <b-tab active title="English">
        <b-card-text>
          <validation-observer ref="thingsToDoForm">
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="todo.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <form-group label="Button URL">
                      <b-form-input
                        class="mt-2"
                        v-model="todo.buttonURL"
                        placeholder="Button URL"
                      />
                    </form-group>

                    <b-form-input
                      class="mt-2"
                      v-model="todo.buttonTitle"
                      placeholder="Button Text"
                    />

                    <b-form-textarea
                      class="mt-2"
                      v-model="todo.description"
                      id="textarea-rows"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="todo.thumbnailImg" md="3">
                  <b-img v-bind="mainProps" rounded :src="todo.thumbnailImg" />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
      <b-tab title="Hindi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="todo.translations.hi.title"
                    placeholder="Title (Hindi)"
                  />
                    <form-group label="Button URL">
                      <b-form-input
                        class="mt-2"
                        v-model="todo.translations.hi.buttonURL"
                        placeholder="Button URL(Hindi)"
                      />
                    </form-group>
                  <b-form-input
                    class="mt-2"
                    v-model="todo.translations.hi.buttonTitle"
                    placeholder="Button Text (Hindi)"
                  />

                  <b-form-textarea
                    class="mt-2"
                    v-model="todo.translations.hi.description"
                    id="textarea-rows"
                    placeholder="Description (Hindi)"
                  />
                </b-form-group>
                
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
      <b-tab title="Marathi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="todo.translations.mr.title"
                    placeholder="Title (Marathi)"
                  />

                  <b-form-input
                    class="mt-2"
                    v-model="todo.translations.mr.buttonTitle"
                    placeholder="Button Text (Marathi)"
                  />

                  <form-group label="Button URL">
                      <b-form-input
                        class="mt-2"
                        v-model="todo.translations.mr.buttonURL"
                        placeholder="Button URL(Marathi)"
                      />
                  </form-group>


                  <b-form-textarea
                    class="mt-2"
                    v-model="todo.translations.mr.description"
                    id="textarea-rows"
                    placeholder="Description (Marathi)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
    </b-tabs>
    <b-card-text class="mt-2">
      <b-row>
        <b-col>
          <b-button @click="updateThingsToDo" variant="primary"
            >Submit</b-button
          >
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ComponentMediaSelector,
    BImg,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      required,
      todoID: "",
      mainProps: {
        width: 175,
        height: 175,
      },
      todo: {
        title: "",
        description: "",
        thumbnailImg: "",
        buttonTitle: "",
        buttonURL: "",
        translations: {
          hi: {
            title: "",
            buttonTitle: "",
        buttonURL: "",
            description: "",
          },
          mr: {
            title: "",
            buttonTitle: "",
        buttonURL: "",
            description: "",
          },
        },
      },
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
     getPage() {
      axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/thingsToDo/get`,
              {
                id: this.$route.params.slug
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.todo = response.data
              console.log(response.data)
              this.todoID = response.data._id
              const { translations } = response.data
              console.log(translations)
              this.todo.translations = {
                hi : {
                    title: translations && translations.hi.title ? translations.hi.title: "",
            description:translations && translations.hi.description ? translations.hi.description: "",
            buttonTitle:translations && translations.hi.buttonTitle ? translations.hi.buttonTitle: "",
            buttonURL:translations && translations.hi.buttonURL ? translations.hi.buttonURL: ""
                },
                mr: {
                    title: translations && translations.mr && translations.mr.title ? translations.mr.title: "",
            description:translations && translations.mr && translations.mr.description ? translations.mr.description: "",
            buttonTitle:translations && translations.mr && translations.mr.buttonTitle ? translations.mr.buttonTitle: "",
            buttonURL:translations && translations.mr && translations.mr.buttonURL ? translations.mr.buttonURL: ""
                }
              }
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating todo!"
              );
            });
    },
    handleMediaSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        this.todo.thumbnailImg = mediaObject.media.src;
      }
    },
    updateThingsToDo() {
      this.$refs.thingsToDoForm.validate().then((success) => {
        if (success) {
          if (!this.todo.thumbnailImg) {
            this.makeToast(
              "danger",
              "Error",
              "Select an Image for the To Do item!"
            );
            return;
          }
          console.log(this.todo)
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/thingsToDo/update`,
              {
                id: this.todoID, updates: this.todo
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been updated successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while updating To Do item!"
              );
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>